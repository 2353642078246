import React from 'react';
import { Link } from "gatsby";
import styled from 'styled-components'
//Elements 
import Title from '../../elements/Title'
import CaseStudy from '../../elements/CaseStudy'
import { ContentBlock } from '../../static/css/style'
const CaseStudyWrap = styled.div`
    display: grid;
    grid-template-columns:1fr 1fr 1fr;
    grid-template-rows:auto;
    gap:5vw;
    width: 100%;
    flex-wrap: wrap;
    margin:auto;
    @media screen and (min-width: 1920px) {
        column-gap:10vw;
        row-gap:5vw;
    }
    @media screen and (max-width: 991px) {
        grid-template-columns:1fr 1fr;
    }
    @media screen and (max-width: 479px) {
        margin-top: 8%;
        grid-template-columns:1fr;
    }
`;

const PostsByTag = (props) => {
    return (
        <div className="bodyBlack">
            <ContentBlock>
                <Title size="lg" color="white" weight="bold" bottom="3%">{props.categoryName}</Title>
                <CaseStudyWrap>
                    {props.data.allWpPost.nodes.map(({ node }, index) => (
                        <Link to={props.data.allWpPost.nodes[index].categories.nodes[0].name == "Case Study" ? "/project/" + props.data.allWpPost.nodes[index].slug + "/" : "/journal/" + props.data.allWpPost.nodes[index].slug + "/"} className="text-deco-none">
                            <CaseStudy data={props.data.allWpPost.nodes[index]}></CaseStudy>
                        </Link>
                    ))}
                </CaseStudyWrap>
            </ContentBlock>
        </div>
    );
};

export default PostsByTag;

