import React from "react"
// import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostsByTag from '../views/PostsByTag/PostsByTag'

export default function PostPage({ data, pageContext }) {
  const { TagName } = pageContext
  return (
    <Layout>
      <SEO title={"Tag - " + TagName} />
      <PostsByTag data={data} categoryName={TagName}></PostsByTag>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String) {
    allWpPost(filter: {tags: {nodes: {elemMatch: {slug: {eq: $slug}}}}}) {
        nodes {
          title
          content
          date
          slug
          excerpt
          categories {
            nodes {
              slug
              name
            }
          }
          summary {
            summary
          }
          tags {
            nodes {
              slug
              name
            }
          }
          featuredImage {
            node {
              mediaItemUrl
            }
          }
        }
    }
  }
`